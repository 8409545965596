import React from 'react';
import './Header.css'; // Importez le fichier CSS correspondant

const Header = () => {
    return (
        <div>
            <header>
                <img id="logo" src="./images/bird.png" alt="Logo GoldStory" />
                <h2 id="htitre">GoldStory</h2>
                <div onClick={navigPop}><img id="menu" src="./images/menu.png" alt="Menu" /></div>
                <nav>
                    <ul id="navul">
                        <li><a href="/">Accueil</a></li>
                        <li><a href="/offres">Offres</a></li>
                        <li><a href="/offres">Contact</a></li>
                        <li><a href="/loginPage">Connexion</a></li>
                    </ul>
                </nav>
            </header>
        </div>
    );
};

export default Header;

function navigPop() {
    var x = document.getElementById("navul");
    if (x.style.display === "block") {
        x.style.display = "none";
    } else {
        x.style.display = "block";
    }
}
