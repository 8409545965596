import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBiiTPzXjGngjrlAr3X2neBy-lV7DaKA-w",
  authDomain: "betterflight-6922f.firebaseapp.com",
  databaseURL: "https://betterflight-6922f-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "betterflight-6922f",
  storageBucket: "betterflight-6922f.appspot.com",
  messagingSenderId: "637781116425",
  appId: "1:637781116425:web:c8f18028482853cc2a5ad8"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and Auth
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
