
export const initScrollEffect = () => {
    const pages = document.querySelectorAll('.page');

    function checkVisibility() {
        pages.forEach((page) => {
            const rect = page.getBoundingClientRect();
            const isVisible = (rect.top < window.innerHeight && rect.bottom >= 0);

            if (isVisible) {
                page.classList.add('active');
            } else {
                page.classList.remove('active');
            }
        });
    }

    window.addEventListener('scroll', checkVisibility);
    window.addEventListener('resize', checkVisibility);

    // Vérifiez la visibilité initiale au chargement de la page
    checkVisibility();

    // Clean up the event listeners when the component is unmounted
    return () => {
        window.removeEventListener('scroll', checkVisibility);
        window.removeEventListener('resize', checkVisibility);
    };
};


 
const navigPop = () => {
    var x = document.getElementById("navul");
    if (x.style.display === "block") {
        x.style.display = "none";
    } else {
        x.style.display = "block";
    }
};