import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './MainPage';
import DisplayData from './DisplayData'; // Exemple d'autre composant que vous pourriez utiliser
import AddPost from './AddPost'; 
import loginPage from './loginPage'; 

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/login" element={<loginPage />} />
                    <Route path="/offres" element={<DisplayData />} />
                    <Route path="/new" element={<AddPost />} />
                    
                    
                </Routes>
            </div>
        </Router>
    );
}

export default App;
