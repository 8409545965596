import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase';
import Header from './Header';
import './DisplayData.css'; // Importez le fichier CSS correspondant

const DisplayData = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'offres'));
                const postsData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setPosts(postsData);
            } catch (error) {
                console.error("Error fetching documents: ", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>

            <Header />
            <div className="search-filter">
                <input type="text" id="search" placeholder="Rechercher un service..." />
                <select id="filter">
                    <option value="all">Tous les services</option>
                    <option value="photography">Photographie aérienne</option>
                    <option value="inspection">Inspection industrielle</option>
                    <option value="agriculture">Suivi agricole</option>
                    <option value="events">Événements</option>
                </select>
                <button>Rechercher</button>
            </div>
            <main>
                <section className="offers">
                    {posts.map((post) => (
                        <div className="offer" key={post.id}>
                            <img src="../images/bird.png" alt={`Drone Service ${post.id}`} />
                            <div className="offer-content">
                                <h3>{post.titre}</h3>
                                <p>{post.description}</p>
                                <div id="textBox">
                                <p>{post.duree} h</p>
                                <p>{post.prix} euros</p>
                                <p>{post.entreprise}</p>
                                <p>{post.option}</p>
                                <p>{post.localisation}</p>
                                </div>
                                <a href="./reservation.html"><button>Réserver</button></a>
                            </div>
                        </div>
                    ))}
                </section>
            </main>
            <footer>
                <p>&copy; 2024 Gold Story. Tous droits réservés.</p>
            </footer>
        </div>
    );
};

export default DisplayData;


