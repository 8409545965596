import React, { useEffect } from 'react';
import Header from './Header';
import { initScrollEffect } from './scrollEffect';
import './MainPage.css';

const MainPage = () => {
    useEffect(() => {
        const cleanUp = initScrollEffect();
        return cleanUp;
    }, []);
    return (
        <div>
            <Header />
            <main>
                <div id="firstView">
                    <div id="first">
                        <div id="titre">
                            <h1>GOLD</h1>
                            <h2>STOR<span style={{color: '#0077B5', fontFamily: 'Krona One'}}>Y</span></h2>
                            <h3>Vos idées prennent de la hauteur!</h3>
                        </div>
                        <img src="/images/bird.png" id="logo" alt="Bird Logo" />
                    </div>
                    <div id="firstBis">
                        <div id="firstBisImg" className="page">
                            <img src="/images/first.jpg" alt="First" />
                        </div>
                        
                    </div>
                </div>
                <div id="secondPage">
                    <h2>GoldStory propose des prestations de prises de vues aérienne pour des événements uniques, culturels et sportifs, pour effectuer des inspections de bâtiments, cartographier un terrain. GoldStory vous accompagne dans vos projets drone avec notre expertise sur l'aspect technique et réglementaire.</h2>
                    <div id="secondDiv">
                        <div id="secondDivBox" className="page">
                            <h4>Événements</h4>
                            <p>Nous proposons une large gamme de prises de vues aériennes pour immortaliser vos moments. Mariages, Sports, Salons ou tout autre événement</p>
                        </div>
                        <div id="secondDivBox" className="page">
                            <h4>Inspection</h4>
                            <p>Nous proposons une offre complète pour des inspections de bâtiments. Ces offres peuvent comprendre les prises de vues aériennes précises et une étude détaillée de votre structure.</p>
                        </div>
                        <div id="secondDivBox" className="page">
                            <h4>Cartographie</h4>
                            <p>Nous proposons une offre de cartographie pour des terrains et des bâtiments. Une vue aérienne de votre site/structure pour vos optimisations ou suivi d'évolution</p>
                        </div>
                        <div id="secondDivBox" className="page">
                            <h4>Gestion de projet</h4>
                            <p>GoldStory étudie vos projets drone et vous accompagne. Matériel, logiciel, réglementation, pilotage, nous avancerons à vos côtés pour vos projets d'industrialisation.</p>
                        </div>
                    </div>
                    <h2>GoldStory offre une expertise professionnelle avec une maîtrise totale pour chaque projet. Nos partenaires, non seulement expérimentés et conformes à la réglementation, sont également équipés de matériel performant et adapté à de nombreuses utilisations, assurant ainsi qualité et sérénité pour la mise en place de nos solutions.</h2>
                    <div id="imgForet"><img src="./images/foret.png" alt="Forêt" /></div>
                    <div id="prezpage">
                        <div className="page">
                            <img src="./images/quadri2.png" alt="Compétences" />
                            <h4>Compétences & expertises</h4>
                            <p>Expert dans le pilotage, le traitement des images et la gestion de projet, nos compétences et celles de nos partenaires nous permettent de proposer des offres complètes et qualitatives.</p>
                        </div>
                        <div className="page">
                            <img src="./images/quadri3.png" alt="Qualité" />
                            <h4>Qualité & Sécurité</h4>
                            <p>La qualité et la sécurité sont primordiales chez GoldStory. Accompagné de notre maîtrise de la réglementation et des règles de sécurité, tous les moyens sont mis en place pour effectuer des prestations en toute sécurité.</p>
                        </div>
                        <div className="page">
                            <img src="./images/quadri1.png" alt="Gestion de Projet" />
                            <h4>Gestion de Projet</h4>
                            <p>Chez GoldStory, nous vous accompagnons dans vos projets. Grâce à notre réseau de partenaires et nos ressources techniques et réglementaires, nous étudions vos projets pour vous accompagner jusqu'à la réalisation.</p>
                        </div>
                    </div>
                </div>
                <div id="cardPage">
                    <div id="carte" className="page">
                        <h1>GoldStory Project</h1>
                        <h2>Entreprise de service dans l'industrie droniste</h2>
                        <h4>Mail : contact@goldstoryproject.fr</h4>
                        <h4>Tél : 06.00.00.00.00</h4>
                        <h4>Gironde</h4>
                        <h4>482 Avenue de Verdun - 33700 Mérignac</h4>
                        <img src="./images/bird.png" alt="Bird Logo" />
                    </div>
                    <div id="pic" className="page">
                          </div>  
                </div>
                <div id="contact" className="page">
                    <h2>Quel est votre projet :</h2>
                    <h3>Une prise de vue aérienne pour un événement, un terrain, une inspection ou un projet nécessitant un drone, décrivez-nous votre besoin</h3>
                    <form id="contact-form" action="./content/contact.php" method="post">
                        <label htmlFor="name">Nom :</label>
                        <input type="text" id="name" name="name" required />
                        <label htmlFor="email">Adresse mail :</label>
                        <input type="email" id="email" name="email" required />
                        <label htmlFor="message">Votre besoin (prise de vue, inspection, gestion de projet drone) :</label>
                        <textarea id="message" name="message" rows="6" required></textarea>
                        <button type="submit">Envoyer</button>
                    </form>
                </div>

            </main>
        </div>
    );
};

export default MainPage;




