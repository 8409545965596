import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from './firebase';
import Header from './Header';
import './AddPost.css';

const AddPost = () => {
    const [titre, setTitre] = useState('');
    const [description, setDescription] = useState('');
    const [duree, setDuree] = useState('');
    const [prix, setPrix] = useState('');
    const [entreprise, setEntreprise] = useState('');
    const [option, setOption] = useState('');
    const [localisation, setLocalisation] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, 'offres'), {
                titre,
                description,
                duree,
                prix,
                entreprise,
                option,
                localisation,
                createdAt: new Date()
            });
            setTitre('');
            setDescription('');
            setDuree('');
            setPrix('');
            setEntreprise('');
            setOption('');
            setLocalisation('');
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    return (
        <div>
        <Header />
        <div className="add-post">
            
            <form onSubmit={handleSubmit}>
                <input 
                    type="text" 
                    placeholder="Titre" 
                    value={titre}
                    onChange={(e) => setTitre(e.target.value)} 
                    required 
                />
                <input 
                    type="text" 
                    placeholder="Description" 
                    value={description}
                    onChange={(e) => setDescription(e.target.value)} 
                    required 
                />
                <input 
                    type="text" 
                    placeholder="Durée" 
                    value={duree}
                    onChange={(e) => setDuree(e.target.value)} 
                    required 
                />
                <input 
                    type="text" 
                    placeholder="Prix" 
                    value={prix}
                    onChange={(e) => setPrix(e.target.value)} 
                    required 
                />
                <input 
                    type="text" 
                    placeholder="Entreprise" 
                    value={entreprise}
                    onChange={(e) => setEntreprise(e.target.value)} 
                    required 
                />
                <input 
                    type="text" 
                    placeholder="Option" 
                    value={option}
                    onChange={(e) => setOption(e.target.value)} 
                    required 
                />
                <input 
                    type="text" 
                    placeholder="Localisation" 
                    value={localisation}
                    onChange={(e) => setLocalisation(e.target.value)} 
                    required 
                />
                <button type="submit">Ajouter l'offre</button>
            </form>
        </div></div>
    );
};

export default AddPost;
